import React from 'react'
import './Search.css'
import axios from 'axios'
import Movies from './Movies'
import Tv from './Tv'
import { Link } from 'react-router-dom'


export default function Search() {

  const [searchBar, setSearchBar] = React.useState()
  const [result, setResult] = React.useState()



  React.useEffect(() => {

    console.log(window.location.pathname)
    axios({
      method: 'get',
      baseURL: "/",
      url: 'api/search',
      params: {
        q: searchBar
      }
    })
      .then(res => res.data)
      .then(data => setResult(data.map(each => {

        if (each.media_type === 'movie') {
          return (<Movies data={each} key={each.id} />)
        }
        if (each.media_type === 'tv') {
          return (<Tv data={each} key={each.id} />)
        }

      })))


  }, [searchBar])

  function getResult(e) {
    let val = document.getElementById('search-bar').value;
    setSearchBar(val)
    console.log(val)
  }

  function enter(e) {
    if (e.key === "Enter") {
      getResult(e)
    }
  }


  return (
    <div>
      <header id="head">
        <div id="logo">OpenMovies</div>
        <div id="link">
          {/* <a href="/movies" id="movies">Movies</a>
                <a href="tv" id="tv">TV Shows</a> */}
          <Link to="movies" id="movies">Movies</Link>
          <Link to="tv" id="tv">TV Show</Link>
        </div>
      </header>

      <div id="image-container">
        <div id="search">
          <input type="text" id="search-bar" onKeyDown={enter} ></input>
          <button type="submit" id="search-btn" onClick={getResult} >Search</button>
        </div>
      </div>


      <div id="parent">
        {result}
      </div>


    </div>
  )
}