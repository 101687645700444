import React, { useRef } from 'react'
import Movies from './Movies'
import './LazyLoad.css'
import axios from 'axios'
import Tv from './Tv'


function Home() {

  // const pathname = window.location.pathname //javascript way to get url


  const [movies, setMovies] = React.useState([])
  const [pageNum, setPgaeNum] = React.useState(1)

  let observer = React.useRef()

  let data = useRef([])

  let lastChild = React.useCallback(node => {
    if (node) {
      console.log(node)
      observer.current = new IntersectionObserver(entries => {
        console.log(entries[0])
        if (entries[0].isIntersecting) {
          console.log("visible")
          setPgaeNum(prvPageNum => prvPageNum + 1)
          observer.current.unobserve(entries[0].target)
        }

      })
      observer.current.observe(node)
    }
  }, [])

  React.useEffect(() => {

    const controller = new AbortController()

    axios({
      method: 'get',
      baseURL: "/",
      url: `api${window.location.pathname}`,
      params: {
        page: pageNum
      },
      signal: controller.signal

    })
      .then(res => res.data)
        
      .then(data => {

        if (window.location.pathname === '/movies') {
          setMovies(preMovies => {
            return [...preMovies, ...data.map((each, index) => {
              if (data.length === index + 1) {
                // console.log('Last child created')
                return (<Movies last={lastChild} data={each} key={each.id} />)
              }
              return (<Movies data={each} key={each.id} />)
            })]
          })
        }
        else {
          setMovies(preMovies => {
            return [...preMovies, ...data.map((each, index) => {
              if (data.length === index + 1) {
                // console.log('Last child created')
                return (<Tv last={lastChild} data={each} key={each.id} />)
              }
              return (<Tv data={each} key={each.id} />)
            })]
          })
        }

      }, [])

    return (() => controller.abort())

  }, [pageNum]);

  return (
    <div className="grid">
      {movies}
    </div>

  );
}

export default Home;
