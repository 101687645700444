import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import './Video.css'



export default function Hello() {

  const location = useLocation()
  const type = location.state.type
  console.log(type)

  const [videoId, setVideoId] = useState(null);


  React.useEffect(() => {
    axios({
      method: 'get',
      baseURL: "/",
      url: `api${window.location.pathname}`,
      params: {
        type: type,
      }
    })
      .then(res => res.data)
      .then(data => setVideoId(data))
  })

  return (
    <>
      {/* <p>URL Path id : {id}</p>
            <p>URL video id : {videoId}</p> */}
      <iframe
        width={window.innerWidth}
        height={window.innerHeight}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>

      </iframe>
    </>


  )
}