import React from 'react'
import './Tv.css'
import {Link, useNavigate} from 'react-router-dom'

export default function Movies(props) {

    const img_baseURL = 'https://image.tmdb.org/t/p/w500'

    
    let navigate = useNavigate();
    // console.log(props);

    function takeMeTo(e){
        navigate(`/video/${props.data.id}`, {state: {type : "tv"}});  
    }

    return (
        <div ref = {props.last} onClick = {takeMeTo} > 

            <div className = "grid-item">
                
                <img className = "grid-item-poster" src = {img_baseURL+props.data.poster_path} />    
                
                <div id="icon">
                    <img src="https://cdn-icons-png.flaticon.com/512/1828/1828614.png" alt=""></img>
                    <span>{props.data.vote_average}</span>
                </div>

                <div className = "grid-item-title">
                    <p>{props.data.name}</p>
                    <p>{props.data.first_air_date}</p>
                </div>

            </div>

        </div>  
       
    )
    
}