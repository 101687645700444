import React from 'react'
import LazyLoad from './components/LazyLoad'
import Video from './components/Video'
import Search from './components/Search'
import Home from './components/Home'

import {Routes, Route} from 'react-router-dom'


function App() {

  return (
    <>
      <Routes>
        <Route path = '/' element = {<Home />}/>
        <Route path = '/movies' element = {<LazyLoad/>}/>
        <Route path = '/tv' element = {<LazyLoad/>}/>
        <Route path = '/video/:id/' element = {<Video/>}/>
        <Route path = '/search' element = {<Search/>}/>
      </Routes>
      
    </>
    
  );
}

export default App;
