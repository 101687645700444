import React from 'react'
import axios from 'axios'
import Search from './Search'
import Movies from './Movies'
import Tv from './Tv'
import './Home.css'


export default function Home() {

  const [movie, setMovie] = React.useState();
  const [tv, setTv] = React.useState();


  const [movieLink, setMovieLink] = React.useState();
  const [tvLink, setTvLink] = React.useState();


  function changeClass(e, ele) {
    let childrens = Array.from(ele.children)
    console.log(childrens);
    childrens.forEach((each) => {
      each.classList.remove('active')
    })
    e.target.classList.add("active")
  }



  function changeMovieLink(e) {
    e.preventDefault();
    let ele = document.getElementById('mov_link');
    setMovieLink(new URL(e.target.href).pathname)
    changeClass(e, ele)
  }


  function changeTvLink(e) {
    e.preventDefault();
    let ele = document.getElementById('tv_link');
    setTvLink(new URL(e.target.href).pathname)
    changeClass(e, ele)
  }



  React.useEffect(() => {
    console.log("link UseEffect")
    axios({
      method: 'get',
      baseURL: "/",
      url: movieLink ? movieLink : 'api/movie-populer',
    })
      .then(res => res.data)
      .then(data => data.map(each => {
        return (<Movies data={each} key={each.id} />)
      }))
      .then(fdata => setMovie(fdata))

  }, [movieLink])

  React.useEffect(() => {
    console.log("link UseEffect")
    axios({
      method: 'get',
      baseURL: "/",
      url: tvLink ? tvLink : 'api/tv-populer',
    })
      .then(res => res.data)
      .then(data => data.map(each => {
        return (<Tv data={each} key={each.id} />)
      }))
      .then(fdata => setTv(fdata))

  }, [tvLink])


  return (
    <>
      {<Search />}

      <div className="link">
        <p>Movies</p>
        <div id="mov_link" className="link-movie">
          <a href="api/movie-populer" onClick={changeMovieLink} className='not-active active'>Populer</a>
          <a href="api/movie-latest" onClick={changeMovieLink} className="not-active">Latest</a>
          <a href="api/movie-upcoming" onClick={changeMovieLink} className="not-active">Upcoming</a>
          <a href="api/movie-toprated" onClick={changeMovieLink} className="not-active">TopRated</a>
        </div>
      </div>
      <div id="movie">
        {movie}
      </div>
      <div className="link">
        <p>TV</p>
        <div id="tv_link" className="link-movie">
          <a href="api/tv-populer" onClick={changeTvLink} className='not-active active'>Populer</a>
          <a href="api/tv-airing-this-week" onClick={changeTvLink} className="not-active">Airing This Week</a>
          <a href="api/tv-airing-today" onClick={changeTvLink} className="not-active">Airing_Today</a>
          <a href="api/tv-toprated" onClick={changeTvLink} className="not-active">TopRated</a>
        </div>
      </div>
      <div id="tvshow">
        {tv}
      </div>

    </>


  )
}